/*

  AySay Broadcast bv.

*/

import React from 'react';
import { LanguageContext } from '../languages/Language';
import { makeStyles } from '@material-ui/core/styles';
//import Container from '@material-ui/core/Container'
//import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemLink from '../helpers/ListItemLink';
import EventIcon from '@material-ui/icons/Event';
import { useHistory } from 'react-router-dom';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  header: {
    marginBottom: 10,
  },
  inputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  eventlist: {
    minHeight: 200,
  }
}));

export const SearchEvent = (props) => {

  const classes = useStyles();
  const lang = React.useContext( LanguageContext );
  const history = useHistory();

  const [ link, setLink ] = React.useState("");

  const events = [];

  const { onClose, eventType } = props;

  const openStream = () => {
    if( !link || link.length < 3 ) {
      return;
    }
    let url = link;
    url = url.replace("https://", "").replace("http://", "");
    url = url.replace("www.", "");
    url = url.replace(window.location.host, "").replace("/", "");
    history.push("/" + url);
    if( typeof onClose === "function" ) {
      onClose( url );
    }
  };

  const keyPressed = ( event ) => {
    if(event.keyCode === 13) {
      openStream();
    }
  }

  return(
    <>
    <form noValidate className={classes.root}>

      <Typography variant="h6" align="center">{lang.text.event.search.title}</Typography>

      { eventType === "public" &&
        <Typography variant="caption" align="center">{lang.text.event.search.publicSubtitle}</Typography>
      }
      { eventType === "private" &&
        <Typography variant="caption" align="center">{lang.text.event.search.subtitle}</Typography>
      }
      <br /><br />
      <TextField
        id="km-link-to"
        fullWidth
        className={classes.inputField}
        value={link}
        label={lang.text.event.search.searchlabel}
        variant="outlined"
        autoFocus
        onChange={e=>{setLink(e.target.value)}}
        onKeyDown={keyPressed}
        placeholder={lang.text.event.search.placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton color="primary" edge="end" >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
    <List className={classes.eventlist}>
      { ( !events || !Array.isArray(events) || events.length < 1 ) &&
        <ListItem disabled>
          <ListItemIcon></ListItemIcon>
          <ListItemText primary={lang.text.event.search.noResults} />
        </ListItem>
      }
      { events && events.map( event => (
        <ListItemLink
          to={"/"+event.guid}
          icon={<EventIcon />}
          primary={event.name}
        />
      ))}
    </List>
    </>
  );

}
export default SearchEvent;
