/*

  By Aysay Broadcast b.v.

*/

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import CssBaseline from '@material-ui/core/CssBaseline';

import { ThemeProvider } from "./theme/Theme";
import { LanguageProvider } from "./languages/Language";
import { AuthProvider } from './user/AuthContext';
import Header from './header/Header';
import PageShell from './theme/PageShell';
import Homepage from './home/Homepage';
import Info from './info/Info';
import Contact from './contact/Contact';
import Login from './user/Login';
import Help from './help/Help';
import Loader from './helpers/Loader';

// lazy load event
const Event = React.lazy(()=>import('./event/Event'));

// main app
function App() {
  return (
    <Router>
      <AuthProvider>
        <LanguageProvider>
          <ThemeProvider>
            <CssBaseline />
            <Header />
            <React.Suspense fallback={<Loader open={true} />}>
              <Switch>
                <Route exact path="/">
                  <PageShell>
                    <Homepage />
                  </PageShell>
                </Route>
                <Route path="/info">
                  <PageShell>
                    <Info />
                  </PageShell>
                </Route>
                <Route path="/contact">
                  <PageShell>
                    <Contact />
                  </PageShell>
                </Route>
                <Route path="/login">
                  <PageShell>
                    <Login />
                  </PageShell>
                </Route>
                <Route path="/help">
                  <PageShell>
                    <Help />
                  </PageShell>
                </Route>
                <Route path="/:event_guid">
                  <Event />
                </Route>
              </Switch>
            </React.Suspense>
          </ThemeProvider>
        </LanguageProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
