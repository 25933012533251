/*
Copyright AySay Broadcast b.v.
*/


import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { LanguageContext } from './Language';

import NlFlagIcon from './flags/NL_FlagIcon';
import GbFlagIcon from './flags/GB_FlagIcon';

const LanguageSelector = ( props ) => {

  const languageContext = React.useContext(LanguageContext);

  const [ anchor, setAnchor ] = React.useState( null );
  const [ selectedLanguage, setSelectedLanguage ] = React.useState( "nl" );
  const handleOpen = event => setAnchor(event.currentTarget);
  const handleClose = event => setAnchor(null);
  const handleSelect = lang => {
    setAnchor(null);
    setSelectedLanguage(lang);
    languageContext.setLanguage(lang);
  }

  return(
    <>
    <Button color="primary" aria-label="language selector" onClick={handleOpen}>
      { selectedLanguage === "nl" && <NlFlagIcon style={{ fontSize: '2em' }} />}
      { selectedLanguage === "en" && <GbFlagIcon style={{ fontSize: '2em' }} />}
    </Button>

    <Menu
      keepMounted
      id="language-menu"
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={handleClose}
    >
      <MenuItem onClick={e=>handleSelect("nl")} selected={selectedLanguage === "nl"}>
        <ListItemIcon><NlFlagIcon /></ListItemIcon>
        <ListItemText primary="Nederlands" />
      </MenuItem>
      <MenuItem onClick={e=>handleSelect("en")} selected={selectedLanguage === "en"}>
        <ListItemIcon><GbFlagIcon /></ListItemIcon>
        <ListItemText primary="English" />
      </MenuItem>
    </Menu>
    </>
  );
}
export default LanguageSelector;
