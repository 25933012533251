/*

  By Aysay Broadcast bv.

*/

import React from 'react';
//import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemLink from '../helpers/ListItemLink';

import { AuthContext } from "../user/AuthContext";
import { LanguageContext } from "../languages/Language";

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
//import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export const Menu = ( props ) => {
  const { onClose } = props;
  const lang = React.useContext( LanguageContext );
  const { token, logout, user } = React.useContext( AuthContext );
  const handleClose = () => {
    if(typeof onClose === "function" ) {
      onClose();
    }
  }
  return(
    <List component="nav">

      <ListItemLink
        to="/"
        icon={<HomeOutlinedIcon color="inherit" />}
        primary="Home"
        onClick={handleClose}
      />

      {/*
      <ListItemLink
        to="/info"
        icon={<InfoOutlinedIcon color="inherit" />}
        primary="Over Meeting City"
        onClick={handleClose}
      />
      */}

      {/*
      <ListItemLink
        to="/help"
        icon={<HelpOutlineIcon color="inherit" />}
        primary={lang.text.help.title}
        onClick={handleClose}
      />
      */}

      <ListItemLink
        to="/contact"
        icon={<MailOutlineIcon color="inherit" />}
        primary="Contact"
        onClick={handleClose}
      />

      <ListItemLink
        to="/meetingcitydemo"
        icon={<VisibilityOutlinedIcon color="inherit" />}
        primary="Demo Event"
        onClick={handleClose}
      />

      <ListItemLink
        to={(token)?"/":"/login"}
        icon={<ExitToAppIcon color="inherit" />}
        primary={(token && user)?user.name + " - " + lang.text.login.logout:lang.text.login.title}
        onClick={(token)?async ()=>{await logout();handleClose();}:handleClose}
      />

      <Divider component="li" />
      <ListItem button onClick={handleClose}>
        <ListItemIcon><ExpandLessIcon color="inherit" /></ListItemIcon>
        <ListItemText primary="Menu Sluiten" />
      </ListItem>

    </List>
  );
}
export default Menu;
