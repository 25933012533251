

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const NL_FlagIcon = (props ) => {
  return(
    <SvgIcon viewBox="0 0 640 480" {...props}>
      <rect fill="#21468B" width="600" height="480"/>
      <rect fill="#FFF" width="600" height="320"/>
      <rect fill="#AE1C28" width="600" height="160"/>
    </SvgIcon>
  );
}
export default NL_FlagIcon;
