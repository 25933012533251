/*

  By Aysay Broadcast bv.

*/

import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ContentContainer from '../theme/ContentContainer';
import ContactForm from './ContactForm';
import { ThemeContext } from '../theme/Theme';
import meetingBg from '../theme/img/SVG/vliegveld.svg';

// styles
// const useStyles = makeStyles((theme) => ({
//   root: {},
// }));

export const Contact = ( props ) => {

  const theme = React.useContext( ThemeContext );
  //const classes = useStyles();

  React.useEffect(()=>{
    if( theme.backgroundImage !== meetingBg ) {
      theme.setBackgroundImage(meetingBg);
    }
  });

  return(
    <Container maxWidth="sm">
      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
        <Grid item xs={12}>
          <ContentContainer headerText="Contact Us" solidBg>
            <ContactForm />
          </ContentContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Contact;
