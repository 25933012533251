/*

  By Aysay Broadcast bv.

*/

import React from 'react';
import { LanguageContext } from '../languages/Language';
import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { capitalize } from '../helpers/strings';

export const Breadcrumbs = (props) => {

  const lang = React.useContext( LanguageContext );

  const { location } = props;
  if( !location ) {
    throw new Error("Breadcrumbs: Missing location prop for the breadcrumb trail");
  }

  // location given
  if( typeof location === "string" ) {
    // split by / and remove empty strings
    let parts = location.split("/").filter(v=>v);
    // create partial links for breadcrumbs
    let links = [];
    let linkpath = "";
    for(let i = 0; i < parts.length; i++){
      linkpath += "/" + parts[i];
      links.push( linkpath );
    }
    return (
      <span>
        { parts && parts.length > 0 && parts.map( (item, index) => (
          <React.Fragment key={"sub---" + index}>
            <Typography variant="h6" display="inline"> / </Typography>
            <Link component={RouterLink} to={links[index]} color="inherit">
              <Typography variant="h6" display="inline">{lang.text.breadcrumb[item] || capitalize(item)}</Typography>
            </Link>
          </React.Fragment>
        ))}
      </span>
    );
  }
  else {
    return null;
  }
}

export default Breadcrumbs;
