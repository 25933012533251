export const nl = {
  homepage: {
    header: "Meeting City",
    text: "Welkom op Meeting City.\n\n Maak uw keuze in het menu om uw evenement te betreden.\n\nHet online platform MeetingCity.eu is klaar voor de nieuwe manier van bijeenkomsten. De beleving van een evenement met meerdere zalen is nu digitaal beschikbaar. Alle elementen die u voorheen op een fysiek evenement had, worden samengebracht in dit online event platform. Laat uw bezoekers met elkaar kijken naar presentaties en livestream. Laat ze met elkaar in contact komen via chat en ga in de livestream de interactie aan met uw kijkers.\n\nMeeting City verwelkomt u als bezoeker of organisator.",
  },
  info: {
    title: "Over MeetingCity.eu",
    subtitle: "",
    text: "Meetingcity is een volledig in Nederland ontwikkeld platform dat klaar is voor een nieuwe beleving op het gebied van evenementen.\n\n• Online event platform\n• Interactief\n• Online congress\n• Keynote presentaties\n• Livestream\n• Online lessen\n• Online themadag\n• Online entertainment\n• Productlanceringen\n• Debatten\n• Beurzen\n• Streaming events\n\nHet platform is ontwikkeld voor bedrijven en evenementen organisatoren die in de veranderende omstandigheden een high-end evenement willen organiseren.\n\nAls organisator kunt u verschillende kamers inrichten voor uw kijkers, deze bieden de optie voor uw eigen content. Doormiddel van thema’s zijn de look-and-feel volledig naar uw eigen hand te zetten. U kiest bijvoorbeeld of er een stream zichtbaar moet zijn, hier ook chat bij aanwezig moet zijn ofwel dat deze niet nodig is.\n\nKijkers kunnen in kaart gebracht worden waar deze kijken, hoe lang ze kijken en of ze in bepaalde gebieden verhoogde interesse hebben. Dit kan voor u erg nuttige informatie opleveren.",
    text2: ""
  },
  breadcrumb: {
    schedule: "Programma",
    attendees: "Deelnemers",
    organisation: "Organisatie",
    wc: "WC"
  },
  contact: {
    emailButtonLabel: "E-mail ons",
    phoneButtonLabel: "Bel ons",
    title: "Contact",
    subtitle: "Voor al uw vragen en opmerkingen",
    form: {
      title: "Neem contact op",
      subtitle: "Vul het formulier in",
      nameLabel: "Naam",
      emailLabel: "E-mail Adres",
      companyLabel: "Bedrijf",
      messageLabel: "Uw Vraag of Opmerking",
      sendButton: "Versturen",
    },
  },
  chat: {
    header: "Chat",
    enableChat: "Chat",
    guest: "Gast",
    username: "Uw naam in de chat",
    noMessages: "Er zijn nog geen chatberichten",
    bePatient: "Even wachten met uw volgende bericht",
    messagePlaceholder: "Uw bericht...",
    sendButtonTooltip: "Plaats uw bericht in de chat",
    newMessages: "Nieuwe Chatberichten",
    daysAgo: "dag(en) geleden",
    hiddenUsername: "Anoniem",
  },
  event: {
    search: {
      title: "Zoeken",
      subtitle: "naar uitgenodigingen & openbare evenementen",
      publicSubtitle: "naar openbare evenementen",
      label: "Zoek Uw Evenement",
      placeholder: "Evenement naam of URL",
      noResults: "Geen evenementen gevonden",
    },
    accesscode: {
      title: "Toegangscode",
      subtitle: "Dit event is beveiligd met een toegangscode",
      label: "Event Toegangscode",
      placeholder: "Voer hier uw toegangscode in",
    },
    infoDesk: {
      title: "Infobalie",
    },
    attendees: {
      title: "Deelnemers",
    },
  },
  login: {
    title: "Login",
    subtitle: "Login en ga door naar uw evenement",
    logout: "Uitloggen",
    form: {
      title: "Login",
      subtitle: "Login en ga door naar uw evenement",
      emailLabel: "Uw E-mail",
      passwordLabel: "Uw Wachtwoord",
      loginButton: "Ga Verder",
    }
  },
  reception: {
    title: "Receptie",
    search: {
      title: "Zoeken",
      subtitle: "Zoek naar evenementen",
    },
    checkin: {
      title: "Check-in",
      subtitle: "Login en bezoek evenement",
    },
    event: {
      subtitle: "Bezoek dit evenement",
    },
    contact: {
      title: "Contact",
      subtitle: "Stel uw vraag of opmerking",
    },
    info: {
      title: "Over Meeting City",
      subtitle: "Onze diensten en bedrijfsinformatie"
    },
    help: {
      title: "Help",
      subtitle: "Zoek hier naar oplossingen",
    }
  },
  help: {
    title: "Help",
    subtitle: "Kijk hier voor oplossingen"
  },
  player: {
    loading: "De uitzending wordt geladen...",
    offline: "Deze uitzending is momenteel niet live",
    noNeedToReload: "U hoeft de pagina niet te herladen",
    passwordRequired: "Voor deze stream is een toegangscode nodig",
  },
}
export default nl;
