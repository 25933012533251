/*

  AySay Broadcast bv.

*/

import React from 'react';
import Settings from '../config';
import { useLocation } from 'react-router-dom';

export const getScreenSize = () => {
  let width = (window.screen.width * window.devicePixelRatio).toString();
  let height = (window.screen.height * window.devicePixelRatio).toString();
  return width + "x" + height;
}

export const usePageView = ( session ) => {
  let location = useLocation();
  React.useEffect( () => {
    if( !location ){
      return;
    }
    // send pageload
    let url = Settings.api.url + "/stats/pageview";
    fetch( url, {
      method: "POST",
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "platform": "meetingcity",
        "time": new Date().getTime(),
        "visitor": session,
        "path": location.pathname || "",
        "ua": navigator.userAgent || "",
        "screensize": getScreenSize(),
      }),
    })
    .then( response => {
      //console.log("pageview send");
    })
    .catch( err => {
      console.error(err);
    });
  }, [location, session]);
}
export default usePageView;
