/*
  Copyright AySay Broadcast bv.
*/

import React from 'react';
import { uuid } from '../helpers/strings';
import Config from '../config';
import usePageView from './usePageView';
const AuthContext = React.createContext();

const tokenname = Config.app.name + "_token";
const sessionname = Config.app.name + "_session";

//------------------------------------------------------------------------------
const loadToken = () => {
  try {
    let token = window.localStorage.getItem( tokenname );
    if( token ) {
      token = token.substring(0, token.length - 10);
      return token;
    }
    return null;
  }
  catch( e ) {
    console.error(e);
    return null;
  }
}
//------------------------------------------------------------------------------
const saveToken = token => {
  try {
    let salt = Math.random().toString(36).substring(3,15);
    //console.log("salt:", salt);
    token = token + salt;
    window.localStorage.setItem( tokenname, token );
    return true;
  }
  catch( e ) {
    console.error(e);
    return false;
  }
}
//------------------------------------------------------------------------------
const loadSession = () => {
  try {
    let session = window.localStorage.getItem( sessionname );
    if(!session) {
      return newSession();
    }
    return session;
  }
  catch( e ) {
    console.error(e);
    return null;
  }
}
//------------------------------------------------------------------------------
const newSession = () => {
  try {
    let new_uuid = uuid;
    console.log("uuid",new_uuid);
    window.localStorage.setItem( sessionname, new_uuid );
    return new_uuid;
  }
  catch(e) {
    console.error(e);
    return null;
  }
}
//------------------------------------------------------------------------------
const decodeTokenPayload = token => {
  let payload = null;
  if( token ) {
    let str = atob(token
      .split(".")[0]
      .replace(/-/g, '+')
      .replace(/_/g, '/'));
    try{
      payload = JSON.parse(str);
    }
    catch(err) {
      console.error(err);
    }
  }
  return payload;
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------

const AuthProvider = ( props ) => {

  const [ loading, setLoading ] = React.useState( false );
  const [ token, setToken ] = React.useState( loadToken() );
  const [ user, setUser ] = React.useState();
  const [ role, setRole ] = React.useState("guest");
  const [ session ] = React.useState( loadSession() );

  usePageView( session );

//------------------------------------------------------------------------------
  const login = React.useCallback( async ( email, password ) => {
    if( !email || !password ) {
      console.error("login(): missing either a email or password");
      return false;
    }
    setLoading(true);
    let url = Config.api.url + '/users/login';
    const options = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( {email: email, password: password} )
    };
    let response = await fetch( url, options );
    if( response.status !== 200 ) {
      setLoading( false );
      return false;
    }
    let json = await response.json();
    if( !json.token ) {
      setLoading( false );
      return false;
    }
    setToken( json.token );
    saveToken( json.token );
    let token_payload = decodeTokenPayload( json.token );
    setUser( token_payload.user );
    setRole( token_payload.role || "guest");
    setLoading(false);
    return true;
  }, []);

//------------------------------------------------------------------------------
  const logout = React.useCallback( async () => {
    setLoading(true);
    let url = Config.api.url + '/users/logout';
    const options = {
      method: 'GET',
      headers: {
        Authorization: (token) ? "Bearer " + token : ""
      }
    };
    let response = await fetch( url, options );
    if( response.status !== 200 && response.status !== 204 ) {
      setLoading( false );
      return false;
    }
    setToken( null );
    setUser( null );
    setRole( "guest" );
    setLoading(false);
    window.localStorage.removeItem( tokenname );
    return true;
  }, [token]);

//------------------------------------------------------------------------------
  const loggedIn = React.useCallback( async () => {
    let payload = decodeTokenPayload( token );
    if( new Date(payload.expires_at).getTime() < new Date().getTime() ) {
      console.log("token expired");
      setToken(null);
      setUser(null);
      setRole(null);
      setLoading(false);
      window.localStorage.removeItem( tokenname );
      return false;
    }
    setLoading( true );
    try {
      const url = Config.api.url + '/users/loggedin';
      const options = {
        method: 'HEAD',
        headers: {
          Authorization: (token)?"Bearer "+token : ""
        }
      };
      let response = await fetch( url, options );
      if( response.status !== 204 ) {
        setToken(null);
        setUser(null);
        setRole(null);
        setLoading(false);
        window.localStorage.removeItem( tokenname );
        return false;
      }
      setUser( payload.user );
      setRole( payload.role || "guest");
      setLoading(false);
    }
    catch( err ) {
      setToken(null);
      setUser(null);
      setRole(null);
      setLoading( false );
      window.localStorage.removeItem( tokenname );
    }
  }, [token]);

//------------------------------------------------------------------------------
  React.useEffect( () => {
    if( token ) {
      loggedIn();
    }
  }, [token, loggedIn]);
//------------------------------------------------------------------------------
  return(
    <AuthContext.Provider
      value={{loading, token, user, role, session, login, logout}}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
//------------------------------------------------------------------------------
