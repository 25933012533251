/*

  By Aysay Broadcast bv.

*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../languages/Language';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom'
import useContact from './useContact';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  inputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  buttons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const ContactForm = ( props ) => {

  const classes = useStyles();
  const lang = React.useContext( LanguageContext );
  const location = useLocation();

  const [ name, setName ] = React.useState("");
  const [ email, setEmail ] = React.useState("");
  const [ company, setCompany ] = React.useState("");
  const [ question, setQuestion ] = React.useState("");
  const [ valid, setValid ] = React.useState( true );

  const { loading, sendMail } = useContact();

  const handleSend = () => {

    if( !name || !email || !question ) {
      console.log("handleSend(): missing props !");
      setValid( false );
      return;
    }

    let contact = {
      name: name,
      email: email,
      msg: question,
      company: company,
      browser: navigator.userAgent,
      page: location.pathname,
    }

    sendMail( contact )
    .then( data => {
      setValid(true);
      setQuestion("");
      if( props.onSuccess === "function" ) {
        props.onSuccess();
      }
    })
    .catch( err => {
      console.log("sendmail error: ", err );
      setValid(false)
    });
  }

  return(

    <form noValidate className={classes.root}>

      <Typography variant="h6" align="center">{lang.text.contact.form.title}</Typography>

      <Typography variant="caption" align="center">{lang.text.contact.form.subtitle}</Typography>

      <br />

      <TextField
        className={classes.inputField}
        label={lang.text.contact.form.nameLabel}
        fullWidth
        variant="outlined"
        value={name}
        onChange={e=>setName(e.target.value)}
        required
        error={!valid}
      />

      <TextField
        className={classes.inputField}
        label={lang.text.contact.form.emailLabel}
        fullWidth
        variant="outlined"
        value={email}
        onChange={e=>setEmail(e.target.value)}
        required
        error={!valid}
      />

      <TextField
        className={classes.inputField}
        label={lang.text.contact.form.companyLabel}
        fullWidth
        variant="outlined"
        value={company}
        onChange={e=>setCompany(e.target.value)}
      />

      <TextField
        label={lang.text.contact.form.messageLabel}
        className={classes.inputField}
        fullWidth
        required
        variant="outlined"
        multiline
        rows={8}
        rowsMax={8}
        value={question}
        onChange={e=>setQuestion(e.target.value)}
        error={!valid}
      />

      <br />
      <br />

      <Button
        fullWidth
        onClick={handleSend}
        disabled={loading}
        color="primary"
        variant="contained"
        endIcon={<SendIcon />}
        className={classes.buttons}
      >
        {lang.text.contact.form.sendButton}
      </Button>

    </form>

  );
}
export default ContactForm;
