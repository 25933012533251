/*

  By Aysay Broadcast bv.

*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { LanguageContext } from '../languages/Language';
import { AuthContext } from '../user/AuthContext';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Collapse';
import LinearProgress from '@material-ui/core/LinearProgress';

import SearchEvent from '../event/SearchEvent';
import LoginForm from '../user/LoginForm';
import ContactForm from '../contact/ContactForm';

import useEvents from '../event/useEvents';

// icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EventIcon from '@material-ui/icons/Event';
//import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    maxHeight: 600,
    overflowY: 'auto',
  },
}));

export const Reception = (props) => {

  const classes = useStyles();
  const lang = React.useContext( LanguageContext );
  const { token } = React.useContext( AuthContext );
  let history = useHistory();

  const { loading, events, listEvents } = useEvents();

  const goto = link => history.push(link);

  // states
  const [ showElement, setShowElement ] = React.useState('start');

  React.useEffect( () => {
    if( token ){
      listEvents();
    }
  }, [token, listEvents]);

  return(
    <div className={classes.root}>

    <Grow in={(showElement==="start")} mountOnEnter unmountOnExit>
      <List>
        <ListItem button onClick={e=>setShowElement("search")} divider>
          <ListItemIcon><SearchIcon color="inherit" /></ListItemIcon>
          <ListItemText primary={lang.text.reception.search.title} secondary={lang.text.reception.search.subtitle} />
        </ListItem>

        { ! token &&
          <ListItem button onClick={e=>setShowElement("login")} divider>
            <ListItemIcon><ExitToAppIcon color="inherit" /></ListItemIcon>
            <ListItemText primary={lang.text.reception.checkin.title} secondary={lang.text.reception.checkin.subtitle} />
          </ListItem>
        }

        { token && events && events.length > 0 && events.map( event => {
          // do not show finished events
          if( new Date(event.enddate).getTime() < new Date().getTime() ) {
            return null;
          }
          if( event.platform !== "meetingcity" ) {
            return null;
          }

          return(
            <ListItem button onClick={e=>goto("/"+event.guid)} divider key={event.id}>
              <ListItemIcon><EventIcon color="inherit" /></ListItemIcon>
              <ListItemText primary={event.name} secondary={lang.text.reception.event.subtitle}/>
            </ListItem>
          );
        })}

        { loading &&
          <ListItem button onClick={e=>setShowElement("login")} divider>
            <ListItemText primary={<LinearProgress color="primary" />} />
          </ListItem>
        }

        <ListItem button onClick={e=>setShowElement("contact")} divider>
          <ListItemIcon><MailOutlineIcon color="inherit" /></ListItemIcon>
          <ListItemText primary={lang.text.reception.contact.title} secondary={lang.text.reception.contact.subtitle} />
        </ListItem>

        {/*
        <ListItem button onClick={e=>goto("/info")} divider>
          <ListItemIcon><InfoOutlinedIcon color="inherit" /></ListItemIcon>
          <ListItemText primary={lang.text.reception.info.title} secondary={lang.text.reception.info.subtitle} />
        </ListItem>
        */}

        {/*
        <ListItem button onClick={e=>goto("/help")}>
          <ListItemIcon><HelpOutlineIcon color="inherit" /></ListItemIcon>
          <ListItemText primary={lang.text.reception.help.title} secondary={lang.text.reception.help.subtitle} />
        </ListItem>
        */}
      </List>
    </Grow>

    <Grow in={(showElement==="search")}  mountOnEnter unmountOnExit>
      <>
        <IconButton color="primary" onClick={e=>setShowElement("start")}>
          <ArrowBackIcon />
        </IconButton>
        <SearchEvent eventType="public" />
      </>
    </Grow>

    <Grow in={(showElement==="login")} mountOnEnter unmountOnExit>
      <>
        <IconButton color="primary" onClick={e=>setShowElement("start")}>
          <ArrowBackIcon />
        </IconButton>
        <LoginForm onSuccess={e=>setShowElement("start")} />
      </>
    </Grow>

    <Grow in={(showElement==="contact")} mountOnEnter unmountOnExit>
      <>
        <IconButton color="primary" onClick={e=>setShowElement("start")}>
          <ArrowBackIcon />
        </IconButton>
        <ContactForm onSuccess={e=>setShowElement("start")} />
      </>
    </Grow>

    </div>
  );
}
export default Reception;
