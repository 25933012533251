/*

  By Aysay Broadcast bv.

*/

export let config = {

  app: {
    name: "MeetingCity",
    titlebar: "MeetingCity.eu",
    domain: "meetingcity.eu",
  },
  api: {
    url: "https://stream3.kijk-mee.nl",
  },

}
export default config;

export const setConfig = ( newConfig ) => {
  config = {...config, ...newConfig };
}
