/*
  Copyright AySay Broadcast bv.
*/

import React from 'react';

import nl from './nl';
import en from './en';
const languages = {
  "nl": nl,
  "en": en
}

const LanguageContext = React.createContext();

const LanguageProvider = ( props ) => {

  const setLanguage = ( lang ) => {
    if(!lang || (lang !== "nl" && lang!=="en") ){lang = "nl"}
    setState({
      text: languages[ lang ],
      current: lang,
      setLanguage: setLanguage
    });
  }

  const [ state, setState ] = React.useState({
    text: languages[ "nl" ],
    current: "nl",
    setLanguage: setLanguage,
  });

  return(
    <LanguageContext.Provider value={state}>
      {props.children}
    </LanguageContext.Provider>
  );
}

export { LanguageContext, LanguageProvider };
