export const en = {
  homepage: {
    header: "Meeting City",
    text: "Welkom op Meeting City.\n\n Maak uw keuze in het menu om uw evenement te betreden.\n\nHet online platform MeetingCity.eu is klaar voor een nieuwe manier van bijeenkomsten. De beleving van een evenement met meerdere zalen is nu digitaal beschikbaar. Alle elemeneten die u voorheen op een fysiek evenement had, worden samengebracht in dit online event platform. Laat uw bezoekers met elkaar kijken naar presentaties en livestream. Laat ze met elkaar in contact komen via chat en ga in de livestream de interactie aan met uw kijkers.\n\nMeeting City verwelkomt u als bezoeker of organisator.",
  },
  info: {},
  breadcrumb: {
    schedule: "Schedule",
    attendees: "Attendees",
    organisation: "Organisation",
    wc: "WC"
  },
  contact: {
    emailButtonLabel: "E-mail ons",
    phoneButtonLabel: "Bel ons",
    title: "Contact",
    subtitle: "Voor al uw vragen en opmerkingen",
    form: {
      title: "Neem contact op",
      subtitle: "",
      nameLabel: "Naam",
      emailLabel: "E-mail Adres",
      companyLabel: "Company",
      messageLabel: "Uw Vraag of Opmerking",
      sendButton: "Versturen",
    },
  },
  chat: {
    header: "Chat",
    enableChat: "Chat",
    guest: "Guest",
    username: "Your Chat Name",
    noMessages: "No chatmessages yet",
    bePatient: "Please wait...",
    messagePlaceholder: "Your message...",
    sendButtonTooltip: "Add message to chat",
    newMessages: "New Chatmessages",
    daysAgo: "days ago",
    hiddenUsername: "Anonymous",
  },
  event: {
    search: {
      title: "Zoeken",
      subtitle: "Op uitnodiging & publieke evenementen",
      publicSubtitle: "Alleen publieke evenementen",
      label: "Zoek Uw Evenement",
      placeholder: "Evenement naam of URL",
      noResults: "Geen evenementen gevonden",
    },
    accesscode: {
      label: "Event Toegangscode",
      placeholder: "Voer hier uw toegangscode in",
    },
    infoDesk: {
      title: "Infobalie",
    },
    attendees: {
      title: "Attendees",
    },
  },
  login: {
    title: "Aanmelden",
    subtitle: "Meldt u aan en bezoek event",
    logout: "Afmelden",
    form: {
      title: "Aanmelden",
      subtitle: "Meldt uw aan en bezoek uw evenement",
      emailLabel: "Uw E-mail",
      passwordLabel: "Uw Wachtwoord",
      loginButton: "Aanmelden",
    }
  },
  reception: {
    title: "Receptie",
    search: {
      title: "Zoeken",
      subtitle: "Zoek naar evenementen",
    },
    checkin: {
      title: "Check-in",
      subtitle: "Login en bezoek evenement",
    },
    event: {
      subtitle: "Visit Event",
    },
    contact: {
      title: "Contact",
      subtitle: "Stel uw vraag of opmerking",
    },
    info: {
      title: "Over Meeting City",
      subtitle: "Onze diensten en bedrijfsinformatie"
    },
    help: {
      title: "Help",
      subtitle: "Zoek hier naar oplossingen",
    }
  },
  help: {
    title: "Help",
    subtitle: "Kijk hier voor oplossingen"
  },
  player: {
    loading: "Loading broadcast...",
    offline: "This broadcast is currently not live",
    noNeedToReload: "U hoeft de pagina niet te herladen",
    passwordRequired: "Voor deze stream is een toegangscode nodig",
  },
}
export default en;
