/*

  By Aysay Broadcast bv.

*/

import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ContentContainer from '../theme/ContentContainer';

import { ThemeContext } from '../theme/Theme';
import { LanguageContext } from '../languages/Language';
import { parseNewLines } from '../helpers/strings';
import defaultBackgroundImage from '../theme/img/SVG/straat.svg';

// styles
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: 'yellow',
//     width: '100%',
//   },
// }));

export const Info = ( props ) => {

  const { backgroundImage, setBackgroundImage } = React.useContext( ThemeContext );
  const lang = React.useContext( LanguageContext );

  React.useEffect(()=>{
    if( backgroundImage !== defaultBackgroundImage ){
      setBackgroundImage(backgroundImage);
    }
  },[backgroundImage, setBackgroundImage]);

  return(
    <Container maxWidth="lg">
      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
        <Grid item xs={12}>
          <ContentContainer solidBg headerText={lang.text.info.title}>
            <Typography>
              {parseNewLines(lang.text.info.text)}
            </Typography>
          </ContentContainer>
          <br />
        </Grid>
        <Grid item xs={12}>
          <ContentContainer solidBg headerText={lang.text.info.title}>
            <Typography>
              {parseNewLines(lang.text.info.text)}
            </Typography>
          </ContentContainer>
          <br />
        </Grid>
        <Grid item xs={12}>
          <ContentContainer solidBg headerText={lang.text.info.title}>
            <Typography>
              {parseNewLines(lang.text.info.text)}
            </Typography>
          </ContentContainer>
          <br />
        </Grid>
      </Grid>
    </Container>
  );
}
export default Info;
