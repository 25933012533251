/*

  By Aysay Broadcast bv.

*/


import React from 'react';
import Fade from '@material-ui/core/Fade'

export const PageShell = (props) => {

  const [loaded, setLoaded ] = React.useState(false);

  React.useEffect(()=>{
    if( !loaded ) {
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <Fade in={loaded} timeout={{appear: 1600, enter: 1600, exit: 500}}>
      {props.children}
    </Fade>
  );
}

export default PageShell;
