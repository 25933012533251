/*

  By Aysay Broadcast bv.

*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Settings from '../config';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Collapse from '@material-ui/core/Collapse';
import { Link, useLocation } from "react-router-dom";
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import { ThemeContext } from "../theme/Theme";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Breadcrumbs from './Breadcrumbs';
import Menu from './Menu';
import LanguageSelector from '../languages/LanguageSelector';
import Hidden from '@material-ui/core/Hidden';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  logo: {
    maxHeight: 48,
    marginRight: theme.spacing(2),
  },
  breadcrumbs: {
    flexGrow: 1,
  },
}));

// render the Header
export const Header = (props) => {

  const [ showMenu, setShowMenu ] = React.useState(false);
  const classes = useStyles();
  const theme = React.useContext( ThemeContext );
  const cTheme = useTheme();
  const bigScreen = useMediaQuery(cTheme.breakpoints.up(2000));
  let location = useLocation();

  return (
    <AppBar position="static" color="default" className={classes.root}>
      <Container maxWidth={bigScreen?"xl":"lg"} disableGutters>
        <Toolbar disableGutters>
          <Link to="/">
            <img src={theme.logo} alt={Settings.app.titlebar} className={classes.logo} />
          </Link>
          <span className={classes.breadcrumbs}>
            <Hidden xsDown>
              <Breadcrumbs location={location.pathname} />
            </Hidden>
          </span>
          <LanguageSelector />
          <Button color="primary" aria-label="menu" onClick={e=>setShowMenu(!showMenu)}>
            <MenuIcon fontSize="large" />
          </Button>
        </Toolbar>
        <Collapse in={showMenu}>
          <Menu onClose={e=>setShowMenu(false)} />
        </Collapse>
      </Container>
    </AppBar>
  );
}

export default Header;
