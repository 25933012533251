/*

  By Aysay Broadcast bv.

*/

export const capitalize = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

// parse a string to html components
export const parseNewLines = string =>
  string.split("\n").map(
    (value, index) => (<span key={index}>{value}<br /></span>)
  );

// generate a uuid
export const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
  let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});
