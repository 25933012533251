/*

  by Aysay Broadcast bv.

*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import IconButton from '@material-ui/core/IconButton';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import ExpandLessIcon from '@material-ui/icons/ExpandLess';
//import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  normal: {
    width: '100%',
    minWidth: 155,
    backgroundColor: 'rgba(234,249,252,0.6)',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[16],
    position: 'relative',
    boxSizing: 'border-box',
  },
  dense: {
    width: '100%',
    minWidth: 155,
    backgroundColor: 'rgba(234,249,252,0.6)',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[16],
    position: 'relative',
    boxSizing: 'border-box',
  },
  header: {
    position: "relative",
    margin: theme.spacing(2),
    marginBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  headerCollapse: {
    position: "relative",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.3)',
    }
  },
  content: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(2),
    maxWidth: 'calc(100% - ' + theme.spacing(4) + 'px)',
  },
  contentWithWhiteBg: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255,255,255,0.95)', //theme.palette.background.default,
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    maxWidth: 'calc(100% - ' + theme.spacing(4) + 'px)',
  },
  expandIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 1,
  },
  filler: {
    height: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export const ContentContainer = ( props ) => {

  const classes = useStyles();

  const { solidBg, headerText, children, collapse } = props;
  const [ open, setOpen ] = React.useState( (collapse) ? false : true );
  const handleCollapse = e => {
    if(collapse) {
      setOpen(!open);
    }
  }
  return(
    <Grid container direction="column" justify="flex-start" alignItems="stretch" className={(collapse)?classes.dense:classes.normal}>
      {/*
      <Collapse in={(collapse && open)}>
        <Grid item className={classes.filler}></Grid>
      </Collapse>
      */}
      { headerText &&
        <Grid item className={(collapse)?classes.headerCollapse:classes.header} onClick={handleCollapse}>
          <Typography variant="h6" align="left">
            <strong>{headerText}</strong>
          </Typography>
          {/* collapse &&
            <IconButton edge="end" size="small" className={classes.expandIcon} onClick={handleCollapse}>
              { open &&
                <ExpandLessIcon style={{color: '#000'}}/>
              }
              { !open &&
                <ExpandMoreIcon style={{color: '#000'}}/>
              }
            </IconButton>
          */}
        </Grid>
      }
      {/*<Collapse in={open}>*/}
        {/* collapse &&
          <Grid item className={classes.filler}></Grid>
        */}
        <Grid item className={(solidBg)?classes.contentWithWhiteBg:classes.content}>
          {children}
        </Grid>
        {/* collapse &&
          <Grid item className={classes.filler}></Grid>
          &&
          <Grid item className={classes.filler}></Grid>
        */}
      {/*</Collapse>*/}
    </Grid>
  );
}
export default ContentContainer;
