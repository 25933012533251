/*

  By Aysay Broadcast bv.

*/

import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";

import ContentContainer from '../theme/ContentContainer';
import { ThemeContext } from '../theme/Theme';
import LoginForm from './LoginForm';
import backgroundImage from '../theme/img/SVG/straat.svg';

export const Login = ( props ) => {

  const theme = React.useContext( ThemeContext );
  let history = useHistory();

  const goto = link => history.push(link);

  React.useEffect(()=>{
    if( theme.backgroundImage !== backgroundImage ){
      theme.setBackgroundImage(backgroundImage);
    }
  });;

  return(
    <Container maxWidth="lg">
      <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <ContentContainer solidBg>
            <LoginForm onSuccess={e=>goto("/")} />
          </ContentContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Login;
