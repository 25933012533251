/*

  By Aysay Broadcast bv.

*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { LanguageContext} from '../languages/Language';
import { AuthContext } from './AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  headerText:{
    marginBottom: theme.spacing(1),
  },
  // loginButton: {
  //   marginTop: theme.spacing(1),
  //   minWidth: '100%',
  //   marginBottom: theme.spacing(1),
  //   color: 'white',
  // },
  inputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  container:{
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
  },
  loader: {
    position: 'absolute',
    top: '4rem',
    left: '44%',
    zIndex: 453987,
  },
}));

export const LoginForm = ( props ) => {

  const classes = useStyles();
  const lang = React.useContext(LanguageContext);

  const {loading, login, token} = React.useContext(AuthContext);

  const [email, setEmail ]= React.useState("");
  const [password, setPassword ]= React.useState("");
  const [ error, setError ] = React.useState(false);

  const handleLogin = async (event) => {
    setError(false);
    login(email, password)
    .then( success => {
      if(!success) {
        setError(true);
        return;
      }
      if( typeof props.onSuccess === "function" ) {
        props.onSuccess();
      }
    })
    .catch( err => {
      setError(true);
    });
  }
  const handleEnterKey = ( event ) => {
    if( event.keyCode === 13) {
      handleLogin();
    }
  }

  if( token ) {
    return(
      <div className={classes.root}>
        <Typography>
          You are already logged in
        </Typography>
      </div>
    );
  }

  return(

    <form noValidate className={classes.root}>

      { loading &&
        <div className={classes.container}>
          <CircularProgress className={classes.loader} />
        </div>
      }

      <Typography variant="h6" align="center">{lang.text.login.form.title}</Typography>

      <Typography variant="caption" align="center">{lang.text.login.form.subtitle}</Typography>

      <br />
      <br />

      <TextField id="username"
        label={lang.text.login.form.emailLabel} variant="outlined" fullWidth
        required className={classes.inputField}
        value={email} onChange={e=>setEmail(e.target.value)}
        error={error}
        onKeyDown={handleEnterKey}
        autoFocus
      />

      <TextField id="password"
        type="password" label={lang.text.login.form.passwordLabel} variant="outlined"
        fullWidth required className={classes.inputField}
        value={password} onChange={e=>setPassword(e.target.value)}
        error={error}
        onKeyDown={handleEnterKey}
      />

      <br />
      <br />

      <Button
        className={classes.loginButton}
        variant="contained"
        size="large"
        color="primary"
        endIcon={<ExitToAppIcon />}
        disabled={loading}
        onClick={handleLogin}
      >
        {lang.text.login.form.loginButton}
      </Button>

    </form>

  );
}
export default LoginForm;
