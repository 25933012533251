/*

  by Aysay Broadcast bv.

*/

import React from 'react';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import meetingCityLogo from './img/header_logo.png';
import street_bg from './img/SVG/straat.svg';

const hexToRgb = (hex, alpha) => {

  if(alpha && isNaN(alpha) && alpha.toString().indexOf('.') === -1 ) {
    alpha = undefined;
  }

  let r = 0;
  let g = 0;
  let b = 0;

  // convert
  if( hex.length === 7 ) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }
  else if( hex.length === 4){
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];
  }
  else {
    return 'rgb(0,0,0)';
  }

  if( alpha ) {
    return `rgb(${+r}, ${+g}, ${+b}, ${alpha})`;
  }
  else {
    return `rgb(${+r}, ${+g}, ${+b})`;
  }
}

const default_theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009eef', //#009eef
      contrastText: '#fff',
    },
    secondary: {
      main: '#767676'
    },
    background: {
      default: '#fefefe',
    },
    action: {
      active: hexToRgb('#009eef'),
      hover: hexToRgb('#009eef', '0.1'),
      focus: hexToRgb('#009eef', '0.12' ),
      selected: hexToRgb('#009eef', '0.55'),
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      xxl: 2560,
    },
  },
});

const ThemeContext = React.createContext();

const ThemeProvider = ( props ) => {

  const [ currentTheme, setCurrentTheme ] = React.useState( default_theme );
  const [ logo, setLogo ] = React.useState( meetingCityLogo );
  const [ backgroundImage, setBgImage ] = React.useState( street_bg );

  const setBackgroundImage = React.useCallback( (image) => {
    document.body.style.backgroundColor = '#003d60';
    document.body.style.backgroundImage = `url('${image}')`;
    document.body.style.backgroundRepeat = 'no-repeat no-repeat';
    document.body.style.backgroundPosition = 'center';
    document.body.style.backgroundAttachment = 'fixed';
    document.body.style.backgroundSize = 'cover';
    setBgImage(image);
  }, []);

  const setTheme = React.useCallback( ( primaryColor, secondaryColor, bgImage ) => {
    const newTheme = createMuiTheme({
      palette: {
        primary: {
          main: primaryColor, //'#1a7ea1'
        },
        secondary: {
          main: secondaryColor, //'#767676'
        },
        action: {
          active: hexToRgb(primaryColor),
          hover: hexToRgb(primaryColor, '0.1'),
          focus: hexToRgb(primaryColor, '0.12'),
          selected: hexToRgb(primaryColor, '0.55'),
        }
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
          xxl: 2560,
        },
      },
    });
    if( bgImage && backgroundImage !== bgImage ) {
      setBackgroundImage(bgImage);
    }
    setCurrentTheme(newTheme);
  }, [backgroundImage, setBackgroundImage]);

  const setDefault = React.useCallback( () => {
    setCurrentTheme( default_theme );
    setLogo( meetingCityLogo );
  }, []);

  React.useEffect(()=>{
    setBackgroundImage(backgroundImage);
  },[backgroundImage, setBackgroundImage]);

  return(
    <ThemeContext.Provider value={ {setTheme, setDefault, logo, setLogo, backgroundImage, setBackgroundImage} }>
      <MuiThemeProvider theme={currentTheme}>
        {props.children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
