/*

  By Aysay Broadcast bv.

*/

import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

export const ListItemLink = (props) => {
  const { icon, primary, secondary, to, onClick, className } = props;
  const CustomLink = React.useMemo(
    () => React.forwardRef( (linkProps, ref) => (
      <Link ref={ref} to={to} {...linkProps} />
    )),
    [to],
  );
  return(
    <li className={className}>
      <ListItem button component={CustomLink} onClick={onClick} divider>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    </li>
  );
}
export default ListItemLink;
