/*

  AySay Broadcast bv.

*/

import React from 'react';
import Settings from '../config';
import { AuthContext } from '../user/AuthContext';

export const useEvents = () => {

  const auth = React.useContext( AuthContext );

  const [ loading, setLoading ] = React.useState( false );
  const [ events, setEvents ] = React.useState( [] );

  const listEvents = React.useCallback( async () => {
    if( !auth.token ) {
      setEvents( [] );
    }
    setLoading( true );
    let token = auth.token;
    fetch( Settings.api.url + '/events', {
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: { 'Authorization': "Bearer " + token }
    })
    .then( response => {
      if( response.status === 200 ){
        return response.json();
      }
      else if( response.status === 404 ){
        return { "events": [] };
      }
      else if( response.status === 401 || response.status === 403 ) {
        setLoading(false);
        throw new Error("useEvents: not logged in or your token is not valid");
      }
      else {
        throw new Error("useEvents: Non 200 http response during loading of events: " + response.status );
      }
    })
    .then( json => {
      if( json.events ) {
        setEvents(json.events);
      }
      else {
        setLoading(false);
        throw new Error("useEvents: no events object received in JSON");
      }
      setLoading(false);
    })
    .catch( err => {
      console.error( err );
      setLoading(false);
      setEvents( [] );
    });


  }, [auth.token] );

  return {loading, events, listEvents};
}
export default useEvents;
